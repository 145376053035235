<template>
  <div class="comment">
    <div class="title">
      评论（<span>{{ totalCount }}</span
      >）
    </div>
    <ul>
      <li v-for="item in listComment" :key="item.createUser">
        <router-link
          class="comment-avatar"
          :to="{ name: 'Personal', query: { uid: item.createUser, type: 'mine' } }"
        >
          <img :src="item.user.userImg" alt="" />
        </router-link>
        
        <router-link :to="{ name: 'TopicCommentDetail', query: { id: item.id } }">
          <div class="comment-info">
            <div class="nick-name">
              {{ item.user.nickName }}
            </div>
            <div class="comment-content">
              <div v-html="item.content"></div>
            </div>
            <div class="comment-date">
              
              <!-- <span>{{ dateFormat(timeFormat(item.createTime)) }}</span> -->
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </router-link>

        <a
          v-if="item.likeCount>0"
          href="javascript:;"
          class="comment-star select"
        >
        <!-- 红色点赞 -->
          <img src="@/assets/images/mine/zan2.png" alt="" />
          <span> {{ item.likeCount }}</span>
        </a>

        <a
          v-else
          href="javascript:;"
          @click="onStar(item.id)"
          class="comment-star support-comment"
        >
          <img src="@/assets/images/mine/zan.png" alt="" />
          <span> {{ item.likeCount }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

//  utils

import { timeFormat } from "@/utils/dateFormat";
import { useRouter, onBeforeRouteLeave } from "vue-router";
export default defineComponent({
  name: "Comment",
  props: {
    totalCount: Number,
    listComment: Array,
  },
  setup(props, ctx) {
    function onStar(id: string) {
      ctx.emit("star", id);
    }
    function dateFormat(datetime: string) {
      return datetime.substr(0, 16);
    }
    onBeforeRouteLeave((to, from) => {
      if (to.name=='TopicCommentDetail' && from.name == "NewsDetail") {
        return false;
      }
    });
    return {
      dateFormat,
      onStar,
      timeFormat,
    };
  },
});
</script>

<style lang="scss" scoped>
.comment {
  .title {
    box-sizing: border-box;
    padding: 0 10px;
    color: #444444;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    text-align: left;
  }
  li {
    position: relative;
    padding: 17.5px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    padding-left: 55px;
    .comment-avatar {
      position: absolute;
      left: 10px;
      top: 17.5px;
      img {
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }
  }
}

.nick-name {
  color: #666666;
  font-size: 16px;
  position: relative;
  padding-right: 75px;
}
.comment-info {
  text-align: left;
}
.comment-content {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
  color: #444444;
}
.comment-date {
  margin-top: 10px;
  color: #999999;
  font-size: 14px;
}

.comment-date span {
  display: inline-block;
  margin-right: 10px;
}
.reward {
  padding: 0 7.5px;
  background: #eee;
  height: 22.5px;
  line-height: 22.5px;
  border-radius: 10px;
  margin-left: 10px;
}
.comment-star {
  position: absolute;
  right: 10px;
  top: 17.512px;
  color: #666;
  font-size: 14px;
  img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
}
.select {
  color: #b9135a;
}

.adjacent-news {
  margin: 10px auto;
  text-align: left;
  width: 355px;
  h3 {
    margin: 0;
    font-weight: normal;
    margin: 0 10px;
  }
  a {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #666;
    span {
      color: #05b3d2;
      font-size: 14px;
    }
  }
}
</style>
