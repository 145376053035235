<template>
  <footer>
    <div class="form-field">
      <span @click="showForm" v-show="!isWriting">我也来说两句...</span>
      <div class="form" v-show="isWriting">
        <div class="operation">
          <div class="cancel" @click="hideForm">取消</div>
          <div class="confirm" @click="submit">发表</div>
        </div>
        <textarea
          id="comment"
          @input="handleInput"
          ref="textarea"
          v-model="text"
          name="comment"
          placeholder="我也来说两句..."
        ></textarea>
      </div>

      <img
        v-if="topic.isAlreadyZan > 0"
        class="topic-btn support-topic-btn support-click"
        data-type="1"
        src="@/assets/images/mine/zan2.png"
        alt="点赞红"
        @click="star"
      />
      <img
        v-else
        class="topic-btn support-topic-btn support-click"
        data-type="1"
        src="@/assets/images/mine/zan.png"
        alt="点赞"
        @click="star"
      />

      <img
        v-if="isFav"
        class="topic-btn fav-topic-btn"
        src="@/assets/images/icon6-1.png"
        alt="收藏红"
        @click="onFav"
      />

      <img
        v-else
        class="topic-btn fav-topic-btn"
        src="@/assets/images/icon6-2.png"
        alt="收藏"
        @click="onFav"
      />
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick } from "vue";
export default defineComponent({
  name: "TopicComment",
  props: {
    topic: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modelValue: {
      type: String,
      default: "",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const textarea = ref<HTMLTextAreaElement | null>(null);
    const isWriting = ref(false);
    function showForm() {
      isWriting.value = true;
      nextTick(() => {
        if (textarea.value) {
          textarea.value.focus();
        }
      });
    }
    function hideForm() {
      isWriting.value = false;
      if (textarea.value) {
        textarea.value.blur();
      }
    }
    const text = ref("");

    function delText() {
      text.value = "";
    }
    function submit() {
      ctx.emit("submit", delText);
      hideForm();
    }
    function handleInput(e: Event) {
      ctx.emit("update:modelValue", (e.target as HTMLTextAreaElement).value);
    }
    function star() {
      ctx.emit("star");
    }
    function onFav() {
      const isFav = !!props.isFav;
      ctx.emit("collect", isFav);
    }
    return {
      isWriting,
      showForm,
      hideForm,
      submit,
      handleInput,
      star,
      text,
      onFav,
      textarea,
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  height: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  border-top: 1px solid #eee;
}

.form-field {
  width: 365px;
  height: 40px;
  margin: 8px auto;
  position: relative;
  padding-right: 97px;
  box-sizing: border-box;

  line-height: 40px;
}
.form-field span {
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f4f4f4;
  color: #444444;
  line-height: 40px;
  display: block;
}
.form-field input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  outline: none;
  border: none;
  background-color: transparent;
  vertical-align: middle;
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f4f4f4;
}

.form-field .topic-btn {
  width: 18px;
  height: 17.5px;
  position: absolute;
  right: 10px;
  top: 11px;
}

.form-field .support-topic-btn {
  right: 47.5px;
}
.form {
  border-top: 1px solid #eee;
  position: absolute;
  width: 100%;
  left: 0;
  height: 133px;
  bottom: 0;
  background-color: #fff;
  padding: 7.5px 10px;
  box-sizing: border-box;
  z-index: 100000;
}
.operation {
  height: 35px;
  overflow: hidden;
}

.operation div {
  float: left;
  width: 50%;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
}

.operation .confirm {
  float: right;
  color: #b9135a;
  text-align: right;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 7.5px;
  color: #444444;
  font-size: 14px;
  height: 75px;
  background: #f4f4f4;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
}

.reward {
  display: inline-block;
  padding: 0 7.5px;
  background: #eee;
  height: 22.5px;
  line-height: 22.5px;
  border-radius: 10px;
  margin-left: 10px;
}
</style>
